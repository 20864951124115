import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material/material.module';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AdminRoutingModule } from './admin-routing.module';

import { ProjectListComponent } from './project-list/project-list.component';
import { ProjectCreateComponent } from './project-create/project-create.component';
import { ProjectUpdateComponent } from './project-update/project-update.component';
import { ProjectComponent } from './project/project.component';
import { LoginComponent } from './login/login.component';

import { ChartsModule } from 'ng2-charts';
import { ProjectCardComponent } from './project-card/project-card.component';
import { ProjectFormComponent } from './project-form/project-form.component';
import { ProjectTableComponent } from './project-table/project-table.component';
import { AppsComponent } from './apps/apps.component';
import { MathAppAdminComponent } from './apps/math-app-admin/math-app-admin.component';

@NgModule({
  declarations: [
    ProjectListComponent,
    ProjectCreateComponent,
    ProjectUpdateComponent,
    ProjectComponent,
    LoginComponent,
    ProjectCardComponent,
    ProjectFormComponent,
    ProjectTableComponent,
    AppsComponent,
    MathAppAdminComponent
  ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    MaterialModule,
    BrowserAnimationsModule,
    ChartsModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class AdminModule { }
