import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Exercise } from '../shared/interfaces/exercise';

@Component({
  selector: 'app-project-form',
  templateUrl: './project-form.component.html',
  styleUrls: ['./project-form.component.scss']
})
export class ProjectFormComponent implements OnInit {

  formGroup: FormGroup;
  titleAlert = 'This field is required';
  post: any = '';
  workout: any = {};

  constructor( private formBuilder: FormBuilder ) { }

  ngOnInit() {
    this.createForm();
    this.setChangeValidate();
  }

  createForm() {
// tslint:disable-next-line: max-line-length
    // let emailregex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    this.formGroup = this.formBuilder.group({
      exercise: [null, Validators.required],
      duration: [null, Validators.required],
      calories: [null, Validators.required],
      validate: ''
    });
  }

  setChangeValidate() {
    this.formGroup.get('validate').valueChanges.subscribe(
      (validate) => {
        if (validate === '1') {
          this.formGroup.get('exercise').setValidators([Validators.required, Validators.minLength(3)]);
          this.titleAlert = 'You need to specify at least 3 characters';
        } else {
          this.formGroup.get('exercise').setValidators(Validators.required);
        }
        this.formGroup.get('exercise').updateValueAndValidity();
      }
    );
  }

  get exercise() {
    return this.formGroup.get('exercise') as FormControl;
  }

  onSubmit(post) {
    this.post = post;
    this.workout.exercise = post.exercise;
    this.workout.duration = post.duration;
    this.workout.calories = post.calories;
    console.log('Workout Name: ' + this.workout.exercise);
  }

  pleaseReset() {
    this.post = null;
  }

}
