import { Component } from '@angular/core';

export interface Transaction {
  item: string;
  calories: number;
  fat: number;
  carbs: number;
  protein: number;
}

@Component({
  selector: 'app-project-table',
  templateUrl: './project-table.component.html',
  styleUrls: ['./project-table.component.scss']
})
export class ProjectTableComponent {

  displayedColumns: string[] = ['item', 'calories', 'fat', 'carbs', 'protein'];
  transactions: Transaction[] = [
    {item: 'Beach ball', calories: 200, fat: 14, carbs: 27, protein:  3},
    {item: 'Towel',      calories: 315, fat:  4, carbs: 12, protein:  5},
    {item: 'Frisbee',    calories: 124, fat: 11, carbs: 11, protein: 11},
    {item: 'Sunscreen',  calories: 222, fat:  7, carbs:  9, protein:  6},
    {item: 'Cooler',     calories: 195, fat: 22, carbs: 18, protein:  9},
    {item: 'Swim suit',  calories: 145, fat: 13, carbs: 21, protein:  2},
  ];

  /** Gets the total cost of all transactions. */
  getTotalCalories() {
    return this.transactions.map(t => t.calories).reduce((acc, value) => acc + value, 0);
  }

  getTotalFat() {
    return this.transactions.map(t => t.fat).reduce((acc, value) => acc + value, 0);
  }

  getTotalCarbs() {
    return this.transactions.map(t => t.carbs).reduce((acc, value) => acc + value, 0);
  }

  getTotalProtein() {
    return this.transactions.map(t => t.protein).reduce((acc, value) => acc + value, 0);
  }


}
