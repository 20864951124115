import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-math-app-admin',
  templateUrl: './math-app-admin.component.html',
  styleUrls: ['./math-app-admin.component.scss']
})
export class MathAppAdminComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
